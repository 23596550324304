@mixin responsiveTablet {
    @media (max-width: 850px) {
        @content;
    }
}

@mixin responsivePhone {
    @media (max-width: 450px) {
        @content;
    }
}

.hide-tablet {
    @include responsiveTablet {
        display: none !important;
    }
}

.hide-phone {
    @include responsivePhone {
        display: none !important;
    }
}

.only-tablet {
    display: none;
    @include responsiveTablet {
        display: inline-block !important;
    }
}

.only-phone {
    display: none;
    @include responsivePhone {
        display: inline-block !important;
    }
}
