.login {
    height: 100%;
}

// for amplify login
:root {
    --amplify-font-family: "Nunito Sans", Arial, sans-serif;
    --amplify-font-weight: 300;
    --amplify-text-md: var(--font-size);

    --amplify-primary-color: var(--primary-link);
    --amplify-primary-shade: var(--highlight);
    --amplify-primary-tint: var(--primary-link);
    --amplify-background-color: transparent;
    --amplify-secondary-color: var(--secondary-text);
    --amplify-secondary-tint: var(--popup-error-background);
    --amplify-secondary-contrast: transparent;

    --borderle-color: var(--button-border);
    --container-align: center;
    --container-height: 100%;
    --container-justify: center;
    --font-family: var(--amplify-font-family);
    --label-color: var(--primary-text);

    --close-icon-hover: var(--highlight);
}

.guest-request-tile{
    display: flex;
    justify-content: center;
    height: 100%;
}

.guest-request-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 517.5px;
    padding: 35px 40px;
    box-sizing: border-box;
    margin-bottom: 20px;

    box-shadow: var(--box-shadow, 1px 1px 4px 0 rgba(0, 0, 0, 0.15));


    .guest-request-form-header{
        h3{
            font-size: 26px;
        }
    }

    .guest-request-description{

    }

    .guest-request-footer{
        display: flex;
        flex-grow: 2;
        width: 100%;
        a{
            width: 100%;
            align-self: flex-end;
        }
        button{
            text-transform: uppercase;
            background-color: var(--amplify-primary-color);
            color: white !important;
            height: 60px;
            width: 100%;

            &:hover{
                opacity: 0.8;
            }

        }
    }

}

amplify-authenticator {
    --container-height: 100%;
}

.forward-button {
    display: inline-flex;
    place-items: center;
    gap: 1em;
    padding: 0 1em;
    svg {
        width: 50px;
        fill: #3fce3f;
        transform: scaleX(-1);
    }
}

.zeichnung {
    background: #8888882e;
    padding: 1em;
    border: 4px dashed grey;
}
.storno {
    background: #ff678324;
    padding: 1em;
    border: var(--borderle) #ff6783;
}

.preview-img {
    max-height: 100px;
    margin:0 auto 1em auto;
}

.welcome-breadcrumb{
    padding-bottom: 20px;
}

.welcome-breadcrumb img{
    max-height: 100px;
    margin: auto 0 auto auto;
}

.welcome-breadcrumb h1{
    margin: auto 0;
}


.file-uri {
    &:not(:hover){
        h3{
            color: var(--primary-link);
        }
        .file-svg{
            stroke: var(--primary-link);
        }
    }

    .file-svg{
        height: 3em;
        width: auto;
        fill: var(--highlight)
    }

    text-decoration: none;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
}

.file-svg-center{
    margin: auto 0;
}

.locksystem-keys-edit{
    padding-bottom: 1em;
}

.StripeElement{
    padding-bottom: 1em;
}

.background-blur{
    z-index: 100;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top:0;
    left: 0;
    opacity: 0.7;
    background: black;
}
.upload-info-banner{
    display: flex;
    max-height: 70vh;
    overflow-y: auto;
    justify-content: space-between;

    > div{
        margin:20px;
        padding:0px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    z-index: 101;
    background: var(--background);
    padding: 20px;
    margin-right: 20px;
    flex-flow: row wrap;
    border: 1px solid black;

    position: fixed;
    top:20%;
    .example-container{
        margin-top: auto;
        display: flex;

        img{
            margin:auto;
            max-width: Min(250px, 30vw);
        }
    }

    .abort-btn{
        white-space: nowrap;
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 20px;
        svg{
            max-width: 20px;
        }
    }
}

.agbs li:not(:last-child){
    margin-bottom: 1em;
}