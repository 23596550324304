#root {
    height: 100%;
}

#outer-wrapper {
    height: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: minmax(200px, 17%) 1fr;
    @include responsiveTablet() {
        display: unset;
        grid-template-columns: unset;
    }
}

#sidebar {
    background-color: var(--main);

    @include responsiveTablet {
        max-height: 0;
        overflow: hidden;
        transition: .3s ease max-height;

        &.menu-visible {
            max-height: 380px;
        }
    }

    .logo {
        width: 50%;
        padding: 1em 0 2em;
        @include responsiveTablet() {
            padding: 0.5em 0 0.5em;
            height: 70px;
        }
    }
}

#topbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    @include responsiveTablet() {
        justify-content: space-between;
    }

    background-color: var(--main);
    height: 60px;

    svg {
        fill: var(--secondary-link);
        transition: .6s ease fill;
    }

    :hover {
        color: var(--highlight);
        svg {
            fill: var(--highlight);
        }
    }

    a, div {
        color: var(--secondary-link);
        transition: .6s ease color;
        flex: auto 0;
    }
    .logo-wrapper{
        height: 100%;
        margin-left: 10px;
        .logo{
            height: 90%;
            margin: auto;
        }
    }
}

#content {
    min-height: calc(100vh - 110px);
    box-sizing: border-box;
    max-width: 1600px;
    margin: 0 auto;
    padding: 2em;
    @include responsiveTablet {
        min-height: calc(100vh - 160px);
        padding: 0.5em;
    }
}

#footer {
    width: 100%;
    display: flex;
    padding: 0 1em;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: left;
    @include responsiveTablet() {
        justify-content: center;
    }
    a{
        text-decoration: none;
        padding: 0 0.5em;
        position: relative;
        + a {
            border-left: 1px solid var(--button-font);

        }
        &::after {
            display: block;
            position: absolute;
            bottom: -.125em;
            left: .5em;
            width: 0;
            height: 3px;
            background-color: var(--primary-link);
            content: '';
            transition: width .6s ease
        }
        &:hover {
            color: var(--primary-link);
        }
        &:hover::after {
            width: calc(100% - 1em);
        }
    }
}