:root {
    --background: #fff;
    --shade-1: #999;
    --shade-2: #f2f2f2;
    --shade-3: #ccc;
    --shade-4: #ccc;
    --primary-text: #636363;
    --primary-link: #0b428c;
    --secondary-text: #000;
    --secondary-link: #fff;

    --breadcrumb-link: #0b428c;
    --breadcrumb-link-hover: #fff;

    --button-background: #fff;
    --button-background-hover: #0b428c;
    --button-font: #0b428c;
    --button-font-hover: #fff;
    --button-border: #0b428c;
    --button-border-hover: #0b428c;
    --main: #0b428c;
    --highlight: #fbd400;

    --table-row-highlighted: #e3f2fd;
    --table-row-highlighted-hover: #D5E8F5;

    --borderle: 2px solid;
    --border-radiusale: 0;
    --boldness: 600; // also import the corresponding font!
    --transition: .3s ease background-color;

    --box-shad: 0 0 5px 0 rgba(0, 0, 0, 0.3);

    --font-family: "Nunito Sans", Arial, sans-serif;
    --font-weight: 300;
    --font-size: 18px;
    --font-size-lg: 26px;

    --popup-info-background: #6d8eba;
    --popup-error-background: #ba6d7c;

    font-family: var(--font-family);
    font-weight: var(--font-weight);
    font-size: var(--font-size);
    color: var(--primary-text);
    background-color: var(--background);
}

:root.dark-theme {
    --background: #2d2d2d;
    --shade-1: #f2f2f2;
    --shade-2: #343434;
    --shade-3: #333;
    --shade-4: #444;
    --primary-text: #efefef;
    --primary-link: #fbd400;
    --secondary-text: #fff;
    --secondary-link: #fff;

    --breadcrumb-link: #fff;
    --breadcrumb-link-hover: #fff;

    --button-background: #393939;
    --button-background-hover: #393939;
    --button-font: #fff;
    --button-font-hover: #fbd400;
    --button-border: #393939;
    --button-border-hover: #fbd400;
    --main: #393939;
    --highlight: #fbd400;

    --table-row-highlighted: rgba(227, 242, 253, 0.24);
    --table-row-highlighted-hover: rgba(227, 242, 253, 0.4);

    --borderle: 1px solid;
    --border-radiusale: 0;
    --boldness: 400;
    --transition: .3s ease color;
}

html, body {
    height: 100%;
    padding: 0;
    margin: 0;
}

a {
    color: var(--primary-link);
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;
}
a:hover {
    color: var(--button-font-hover);
}

a.def {
    fill: var(--primary-link);
    &:hover {
        fill: var(--background);
    }
}

#content a:hover {
    background-color: var(--main);
}

h1, h2, h3, h4, h5, h6 {
    color: var(--highlight);
    font-weight: var(--boldness);
}
h1 {
    margin: 0 0 1em;
}

@include responsivePhone() {
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
}

hr {
    border: 1px solid var(--button-border);
}

.safe-actions{
    display: flex;
    justify-content: end;
}

button {
    background-color: transparent;
    padding: .5em 1em;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: var(--font-size);
    font-weight: var(--boldness);
}
#content button {
    background-color: var(--button-background);
    color: var(--button-font);
    border: var(--borderle) var(--button-border);
    transition: var(--transition);

    &:hover, &.secondary-button {
        background-color: var(--button-background-hover);
        color: var(--button-font-hover);
        border-color: var(--button-border-hover);
    }

    &.primary-button {
        background-color: var(--highlight);
        color: var(--shade-2);
        font-weight: bold;
        border-color: var(--button-border-hover);
    }

    &.orange {
        background-color: var(--button-background);
        color: #fcac39;
        border: var(--borderle) #fcac39;

        &:hover {
            background-color: #fcac39;
            color: #fff;
            border-color: #fcac39;
        }
    }

    &.red {
        background-color: var(--button-background);
        color: #ff6783;
        border: var(--borderle) #ff6783;

        &:hover {
            background-color: #ff6783;
            color: #fff;
            border-color: #ff6783;
        }
    }

    &.green {
        background-color: var(--button-background);
        color: #60bd27;
        border: var(--borderle) #60bd27;

        &:hover {
            background-color: #60bd27;
            color: #fff;
            border-color: #60bd27;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: #88888830;
        color: var(--primary-text);
        border-color: #88888830 !important;
    }
}
#content button.submit-button {
    min-height: 46px;
    padding: 0;
    transition: width 0.6s ease;
    svg {
        width: 36px;
        shape-rendering: auto;
        display: none;
    }
    main {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    &[disabled] svg {
        display: block;
    }
}

.default {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-column-gap: 1em;
    @include responsiveTablet {
        grid-template-columns: 100%;
    }

    &.side-by-side-3 {
        grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
    }
}
.form-input {
    margin-bottom: 1em;
}

label {
    display: block;
    padding-bottom: 0.2em;
}

.react-select--is-disabled  *{
    pointer-events: auto;
    cursor: not-allowed;
    color: var(--shade-1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input:not([type=checkbox]), textarea, select ,.react-select__control{
    width: 100%;
    max-width: 100%;
    border: var(--borderle) var(--shade-3);
    background-color: var(--shade-2);
    padding: .5em;
    box-sizing: border-box;
    color: var(--secondary-text);
    line-height: 1;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: normal;

    &:disabled {
        cursor: not-allowed;
        color: var(--shade-1);
    }
    &:focus {
        border-color: var(--primary-link);
        color: var(--secondary-text);
        outline: 0;
    }
}

.react-select__menu{
    background-color: var(--shade-2);
    border: var(--borderle) var(--shade-3);
    border-top: none;
    .react-select__option,.react-select__menu-notice{
        padding: 5px;
    }
    .react-select__option:hover{
        background-color: var(--shade-4);
    }
}

.checkbox-replacer {
    position: relative;
    cursor: pointer;
    background: var(--shade-2);
    border: var(--borderle) var(--shade-3);
    display: inline-grid;
    align-items: center;
    justify-items: center;
    height: 20px;
    width: 20px;
    &.focused {
        border-color: var(--primary-link);
    }
    &.big {
        height: 42px;
        width: 42px;
    }
    svg {
        position: absolute;
    }
}


