/* ci */
.blue           { color: var(--main); }
.highlight      { color: var(--highlight); }
.main           { color: var(--main); }
.primary-link   { color: var(--primary-link); }
.yellow         { color: var(--highlight); }

/* color */
.white       { color: white; }
.red         { color: #ff6783; }
.orange      { color: #ff8a67; }
.green       { color: #3fce3f; }
.grey        { color: grey; }
.lightgrey   { color: #a4a0a0; }


/* text */
.bold { font-weight: var(--boldness); } /* also import corresponding google font! */
.underline {
    text-decoration: underline;
}
.italic {
    font-style: italic;
}

.small   { font-size: small; }
.smaller { font-size: smaller; }
.medium  { font-size: medium; }
.larger  { font-size: larger; }
.large   { font-size: large; }
.x-large { font-size: x-large; }

.right  { text-align: right; }
.left   { text-align: left; }
.center { text-align: center; }


/* dom styling */
.hide { display: none; }
.no-opacity { opacity: 0; }
.flex { display: flex; }
.inline { display: inline; }
.inline-block { display: inline-block; }

.noborder { border: none; }

.fade { opacity: 0.6; }

.relative { position: relative; }

.top-3 { top: 3px; }

.pull-left { float: left; }
.pull-right { float: right; }
.clearfix { clear: both; }

.pointer { cursor: pointer; }


.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.overlay {
    /* parent needs "position: relative;" */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.super-centered {
    /* Put on parent container */
    display: grid;
    place-items: center;
}

.top-center {
    /* Put on parent container */
    display: grid;
    justify-content: center;
    align-items: start;
}

.center-left {
    /* Put on parent container */
    display: grid;
    justify-content: left;
    align-items: center;
}

.center-right {
    /* Put on parent container */
    display: grid;
    justify-content: right;
    align-items: center;
}

.self-center {
    align-self: center;
}

.no-spacing {
    margin: 0 !important;
    padding: 0 !important;
}

.no-padding {
    padding: 0 0 0 0;
}

.m-1 { margin: 1em;}
.pt-0   { padding-top:     0.0em !important; }
.pt-05  { padding-top:     0.5em !important; }
.pt-1   { padding-top:     1.0em !important; }
.pt-2   { padding-top:     2.0em !important; }
.pt-3   { padding-top:     3.0em !important; }

.pb-0   { padding-bottom:  0.0em !important; }
.pb-05  { padding-bottom:  0.5em !important; }
.pb-1   { padding-bottom:  1.0em !important; }
.pb-2   { padding-bottom:  2.0em !important; }
.pb-3   { padding-bottom:  3.0em !important; }
.pb-5   { padding-bottom:  5.0em !important; }

.mb-0   { margin-bottom:   0.0em !important; }
.mb-02  { margin-bottom:   0.2em !important; }
.mb-03  { margin-bottom:   0.3em !important; }
.mb-04  { margin-bottom:   0.4em !important; }
.mb-05  { margin-bottom:   0.5em !important; }
.mb-1   { margin-bottom:   1.0em !important; }
.mb-2   { margin-bottom:   2.0em !important; }
.mb-3   { margin-bottom:   3.0em !important; }
.mb-4   { margin-bottom:   4.0em !important; }
.mb-5   { margin-bottom:   5.0em !important; }
.mb-10  { margin-bottom:  10.0em !important; }

.pr-0   { padding-right:   0.0em !important; }
.pr-1   { padding-right:   1.0em !important; }

.pl-0   { padding-left:    0.0em !important; }
.pl-1   { padding-left:    1.0em !important; }
.pl-2   { padding-left:    2.0em !important; }
.pl-3   { padding-left:    3.0em !important; }

.ml-05  { margin-left:     0.5em !important; }
.ml-1   { margin-left:     1.0em !important; }
.ml-2   { margin-left:     2.0em !important; }
.ml-3   { margin-left:     3.0em !important; }

.mr-05  { margin-right:    0.5em !important; }
.mr-1   { margin-right:    1.0em !important; }
.mr-2   { margin-right:    2.0em !important; }

.mt-0   { margin-top:      0.0em !important; }
.mt-05  { margin-top:      0.5em !important; }
.mt-1   { margin-top:      1.0em !important; }
.mt-1d5 { margin-top:      1.5em !important; }
.mt-2   { margin-top:      2.0em !important; }
.mt-3   { margin-top:      3.0em !important; }
.mt-4   { margin-top:      4.0em !important; }
.mt-5   { margin-top:      5.0em !important; }

.margin-auto {
    margin: auto;
}

.height-100 { height: 100%; }

.width-100 { width: 100%; }
.width-50  { width:  50%; }
.width-20  { width:  20%; }