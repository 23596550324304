svg .main-color {
    fill: var(--main);
}

.main-nav a {
    display: block;
    position: relative;
    color: white;
    text-decoration: none;
    z-index: 1;
    transition: .3s ease color;
    padding: 1em 0;
    @include responsiveTablet {
        padding: 0.5em 0;
    }

    &:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--highlight);
        transition: .3s ease left;
        border-right: solid 5px var(--highlight);
        @include responsiveTablet {
            border-right: none;
        }
    }

    &.current,
    &:hover {
        color: black;

        &:before {
            left: 0;
        }
    }
}

.burger-menu {
    cursor: pointer;
    height: 50px;
    width: 50px;
    svg {
        width: 100%;
    }
}

.theme-switcher {
    display: flex;
    align-items: center;
    margin: 0 3em;
}

.benutzer {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    margin: 0 3em;
    @include responsiveTablet() {
        margin: 0 0;
    }

    svg {
        flex: 0 1 auto;
        width: 33px;
        height: 36px;
    }
}

.logout button {
    height: 60px;
    padding: 0.6em 0;
    margin: 0 1em;

    svg {
        height: 91%;
        width: 50px;
        padding-top: 2px;
    }
}

.logo-lintner {
    width: 130px;

    .cls-2 {
        fill: var(--button-font);
    }
}

.breadcrumb a {
    color: var(--breadcrumb-link);
    fill: var(--breadcrumb-link) !important;
    text-decoration: none;
    padding: 0 5px;
    &:hover {
        color: var(--breadcrumb-link-hover);
        fill: var(--breadcrumb-link-hover) !important;
    }
    svg {
        height: 0.8em;
    }
}

.kacheln {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-column-gap: 1em;
    grid-row-gap: 1em;
}

.kachel {
    padding: 1em;
    background: var(--shade-4);
    border: var(--borderle) var(--main);
    transition: .3s ease all;
    font-size: 26px;
    text-decoration: none;

    &:hover {
        border-color: var(--highlight);
        color: var(--button-font-hover);
    }
    .name {
        padding-top: 0.8em;
        text-align: center;
    }

    svg {
        fill: var(--shade-1);
        display: block;
        margin: 1em auto 1em auto;
        height: 150px;
    }
}

div.alert {
    display: flex;
    align-items: center;
    position: fixed;
    width: calc(100% - 50px);
    max-width: 1500px;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    margin-top: 25px;
    padding: 16px;
    z-index: 10;
    text-align: center;
    box-shadow: var(--box-shad);
    border-radius: 5px;

    &.alert-info {
        background-color: var(--popup-info-background);
    }
    &.alert-error {
        background-color: var(--popup-error-background);
    }

    > :nth-child(1) {
        flex: 1 1 auto;
    }
    > :nth-child(2) {
        font-size: 30px;
        font-weight: var(--boldness);
        line-height: 0;
        cursor: pointer;
        &:hover {
            color: var(--highlight);
        }
    }

}
div[role="row"]:hover .delete-icon {
    opacity: 1;
}
.delete-icon {
    opacity: 0.05;
    padding: 3px 0;
    width: 38px;
}

.no-hover:hover .rdt_TableCell {
    color: var(--primary-text);
}


.rdt_TableCol, .rdt_TableCell{
    min-width: 160px !important;
}

.rdt_TableCell>div{
    white-space: break-spaces !important;
}

a.no-link-hover:hover {
    background-color: unset !important;
}

.data-table{
    position: relative;
}

.data-table-scroll-info{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: none;
    @include responsiveTablet(){
        display: flex;
    }
    backdrop-filter: blur(5px);
    z-index: 1;
    flex-direction: column;
    svg{
        fill: var(--secondary-link);
        max-width: 100px;
        margin: 0 auto;
    }
    p{
        margin: 0 auto;
    }
}

.rdt_Table{ //fix 1px scroll bars
    overflow: hidden;
}

div.data-table-topbar {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    > div:nth-child(1) {
        flex: 1 1 300px;
    }

    > div:nth-child(2) {
        flex: 0 0 auto;
        justify-items: flex-end;
    }
}


.react-select__value-container--is-multi{
    gap: 10px;
}

.react-select__multi-value{
    background-color: var(--background);
    padding: 5px;
}

.react-select__multi-value__remove{
    margin-left: 3px;
}

.bvlmVM{
    position: unset !important;
}

.additional-info{
    font-size: 0.9em;
}

a.attachment{
    margin-right: 10px;
}

.image-input-container{

    .file-svg{
        max-width: 50px;
        stroke: white;
        stroke-width: 5px;
        fill: none;
    }

    .preview-img{
        margin:auto 0;
        max-width: 50px;
    }



    .image-input{
        max-width: 300px;
        width: 100%;
        padding: 0;
        &:hover{
            svg{
                stroke: var(--button-border-hover);
            }
        }

        label{
            width: 100%;
            align-items: center;
            display: flex;
            padding: 10px;
            span{
                margin:auto auto;
            }
        }
    }

}